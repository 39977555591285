import React, { FC, useEffect, useState } from 'react'
import getConfig from 'next/config'
import { LandingPageContainer } from './styled'
import HeroBanner from '@Components/Blocks/HeroBanner'
import PageList from '@Components/Blocks/PageList'

import NLLandingData from '../../../locales/nl/landingpage.json'
import NLGroenStoryData from '../../../locales/nl/groenStory.json'
import NLRichStoryData from '../../../locales/nl/richStory.json'
import NLKleurStoryData from '../../../locales/nl/kleurStory.json'
import NLOntbijtStoryData from '../../../locales/nl/ontbijtStory.json'
import NLChildStoryData from '../../../locales/nl/childStory.json'
import NLStrongStoryData from '../../../locales/nl/strongStory.json'
import NLLoveStoryData from '../../../locales/nl/loveStory.json'
import NLTravelStoryData from '../../../locales/nl/travelStory.json'

import FRLandingData from '../../../locales/fr/landingpage.json'
import FRChildStoryData from '../../../locales/fr/childStory.json'
import FRStrongStoryData from '../../../locales/fr/strongStory.json'
import FRLoveStoryData from '../../../locales/fr/loveStory.json'
import FRTravelStoryData from '../../../locales/fr/travelStory.json'
import FROntbijtStoryData from '../../../locales/fr/ontbijtStory.json'
import FRGroenStoryData from '../../../locales/fr/groenStory.json'
import FRRichStoryData from '../../../locales/fr/richStory.json'

import BELandingData from '../../../locales/be/landingpage.json'
import BEChildStoryData from '../../../locales/be/childStory.json'
import BEStrongStoryData from '../../../locales/be/strongStory.json'
import BELoveStoryData from '../../../locales/be/loveStory.json'
import BETravelStoryData from '../../../locales/be/travelStory.json'
import BEOntbijtStoryData from '../../../locales/be/ontbijtStory.json'
import BEGroenStoryData from '../../../locales/be/groenStory.json'
import BERichStoryData from '../../../locales/be/richStory.json'

import FooterBanner from '@Components/Blocks/FooterBanner'
import { useRouter } from 'next/router'
import HeroBannerDetail from '@Components/Blocks/HeroBannerDetail'
import ContentBanner from '@Components/Blocks/contentBanner'
import Carousel from '@Components/Blocks/Carousel'
import LinkBlock from '@Components/Blocks/LinkBlock'
import VideoBlock from '@Components/Blocks/VideoBlock'
import MediaBlock from '@Components/Blocks/mediaBlock'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

const LinkDataState = {
  heroBanner: {
    title: '',
    subTitle: '',
    paragraph: '',
    button: '',
  },
  links: [
    {
      video: '',
      image: '',
      imageHeight: '',
      cropImageHeight: '',
      imagePosistion: '',
      title: '',
      contentId: '',
      fontFamily: '',
      fontSize: '',
      backgroundColor: '',
      secondaryColor: '',
      subTitle: '',
      link: '',
      boxWidth: '',
      boxHeight: '',
    },
  ],
  bottomBanner: {
    title: '',
    paragraph: '',
    url: '',
  },
}

const GroenDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  content: {
    image: '',
    paragraph: '',
    link: '',
    linkUrl: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  footer: {
    title: '',
    img: '',
  },
}

const RichDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  content: {
    image: '',
    paragraph: '',
    link: '',
    linkUrl: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  carousel: [
    {
      title: '',
    },
  ],
  footer: {
    title: '',
    img: '',
  },
}

const OntbijtDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  content: {
    image: '',
    paragraph: '',
    link: '',
    linkUrl: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  footer: {
    title: '',
    img: '',
  },
}

const KleurDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  content: {
    image: '',
    paragraph: '',
    link: '',
    linkUrl: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  footer: {
    title: '',
    img: '',
  },
}

const ChildDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  content: {
    image: '',
    paragraph: '',
    link: '',
    linkUrl: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  footer: {
    title: '',
    img: '',
  },
}

const StrongDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  content: {
    image: '',
    paragraph: '',
    link: '',
    linkUrl: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  carousel: [
    {
      title: '',
    },
  ],
  footer: {
    title: '',
    img: '',
  },
}
const LoveDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  footer: {
    title: '',
    img: '',
  },
}
const TravelDataState = {
  heroBanner: {
    image: '',
    title: '',
    fontFamily: '',
    fontSizeM: '',
    fontheightM: '',
    subTitle: '',
    paragraph: '',
    secondaryColor: '',
    backgroundColor: '',
  },
  footer: {
    title: '',
    img: '',
  },
}
const LandingPage: FC = () => {
  const [linkData, setLinkData] = useState(LinkDataState)
  const [groenData, setGroenData] = useState(GroenDataState)
  const [richData, setRichData] = useState(RichDataState)
  const [kleurData, setKleurData] = useState(KleurDataState)
  const [ontbijtData, setOntbijtData] = useState(OntbijtDataState)
  const [childData, setChildData] = useState(ChildDataState)
  const [strongData, setStrongData] = useState(StrongDataState)
  const [travelData, setTravelData] = useState(TravelDataState)
  const [loveData, setLoveData] = useState(LoveDataState)

  useEffect(() => {
    if (API_SITE === 'FR') {
      setLinkData(FRLandingData)
      setStrongData(FRStrongStoryData)
      setChildData(FRChildStoryData)
      setLoveData(FRLoveStoryData)
      setTravelData(FRTravelStoryData)
      setOntbijtData(FROntbijtStoryData)
      setGroenData(FRGroenStoryData)
      setRichData(FRRichStoryData)
      return
    }
    if (API_SITE === 'BE') {
      setLinkData(BELandingData)
      setStrongData(BEStrongStoryData)
      setChildData(BEChildStoryData)
      setLoveData(BELoveStoryData)
      setTravelData(BETravelStoryData)
      setOntbijtData(BEOntbijtStoryData)
      setGroenData(BEGroenStoryData)
      setRichData(BERichStoryData)
      return
    }
    setLinkData(NLLandingData)
    setGroenData(NLGroenStoryData)
    setRichData(NLRichStoryData)
    setKleurData(NLKleurStoryData)
    setOntbijtData(NLOntbijtStoryData)
    setStrongData(NLStrongStoryData)
    setChildData(NLChildStoryData)
    setLoveData(NLLoveStoryData)
    setTravelData(NLTravelStoryData)
  }, [])
  const router = useRouter()
  const { page } = router.query

  const createContentPage = () => {
    switch (page) {
      case 'green':
        const {
          heroBanner: groenHeroBanner,
          content: groenContent,
          footer: groenFooter,
        } = groenData
        return (
          <>
            <HeroBannerDetail
              style={{ lineHeight: '0.9' }}
              image={groenHeroBanner.image}
              fontFamily={groenHeroBanner.fontFamily}
              fontSizeM={groenHeroBanner.fontSizeM}
              fontheightM={groenHeroBanner.fontheightM}
              title={groenHeroBanner.title}
              subTitle={groenHeroBanner.subTitle}
              paragraph={groenHeroBanner.paragraph}
              secondaryColor={groenHeroBanner.secondaryColor}
              backgroundColor={groenHeroBanner.backgroundColor}
            />
            <ContentBanner
              image={groenContent.image}
              paragraph={groenContent.paragraph}
              link={groenContent.link}
              linkUrl={groenContent.linkUrl}
              secondaryColor={groenContent.secondaryColor}
              backgroundColor={groenContent.backgroundColor}
            />
            <LinkBlock title={groenFooter.title} img={groenFooter.img} />
          </>
        )
      case 'rich':
        const {
          heroBanner: richHeroBanner,
          content: richContent,
          carousel: richCarousel,
          footer: richFooter,
        } = richData
        return (
          <>
            <HeroBannerDetail
              image={richHeroBanner.image}
              fontFamily={richHeroBanner.fontFamily}
              fontSizeM={richHeroBanner.fontSizeM}
              fontheightM={richHeroBanner.fontheightM}
              title={richHeroBanner.title}
              subTitle={richHeroBanner.subTitle}
              paragraph={richHeroBanner.paragraph}
              secondaryColor={richHeroBanner.secondaryColor}
              backgroundColor={richHeroBanner.backgroundColor}
            />
            <ContentBanner
              image={richContent.image}
              paragraph={richContent.paragraph}
              link={richContent.link}
              linkUrl={richContent.linkUrl}
              secondaryColor={richContent.secondaryColor}
              backgroundColor={richContent.backgroundColor}
            />
            <Carousel items={richCarousel} />
            <LinkBlock title={richFooter.title} img={richFooter.img} />
          </>
        )
      case 'breakfast':
        const {
          heroBanner: ontbijtHeroBanner,
          content: ontbijtContent,
          footer: ontbijtFooter,
        } = ontbijtData
        return (
          <>
            <HeroBannerDetail
              image={ontbijtHeroBanner.image}
              fontFamily={ontbijtHeroBanner.fontFamily}
              fontSizeM={ontbijtHeroBanner.fontSizeM}
              fontheightM={ontbijtHeroBanner.fontheightM}
              title={ontbijtHeroBanner.title}
              subTitle={ontbijtHeroBanner.subTitle}
              paragraph={ontbijtHeroBanner.paragraph}
              secondaryColor={ontbijtHeroBanner.secondaryColor}
              backgroundColor={ontbijtHeroBanner.backgroundColor}
            />
            <ContentBanner
              image={ontbijtContent.image}
              paragraph={ontbijtContent.paragraph}
              link={ontbijtContent.link}
              linkUrl={ontbijtContent.linkUrl}
              secondaryColor={ontbijtContent.secondaryColor}
              backgroundColor={ontbijtContent.backgroundColor}
            />
            <LinkBlock title={ontbijtFooter.title} img={ontbijtFooter.img} />
          </>
        )
      case 'colorful':
        const {
          heroBanner: kleurHeroBanner,
          content: kleurContent,
          footer: kleurFooter,
        } = kleurData
        return (
          <>
            <HeroBannerDetail
              style={{ lineHeight: '0.9' }}
              image={kleurHeroBanner.image}
              fontFamily={kleurHeroBanner.fontFamily}
              fontSizeM={kleurHeroBanner.fontSizeM}
              fontheightM={kleurHeroBanner.fontheightM}
              title={kleurHeroBanner.title}
              subTitle={kleurHeroBanner.subTitle}
              paragraph={kleurHeroBanner.paragraph}
              secondaryColor={kleurHeroBanner.secondaryColor}
              backgroundColor={kleurHeroBanner.backgroundColor}
            />
            <ContentBanner
              image={kleurContent.image}
              paragraph={kleurContent.paragraph}
              link={kleurContent.link}
              linkUrl={kleurContent.linkUrl}
              secondaryColor={kleurContent.secondaryColor}
              backgroundColor={kleurContent.backgroundColor}
            />
            <LinkBlock title={kleurFooter.title} img={kleurFooter.img} />
          </>
        )
      case 'child':
        const {
          heroBanner: childHeroBanner,
          content: childContent,
          footer: childFooter,
        } = childData
        return (
          <>
            <HeroBannerDetail
              image={childHeroBanner.image}
              fontFamily={childHeroBanner.fontFamily}
              fontSizeM={childHeroBanner.fontSizeM}
              fontheightM={childHeroBanner.fontheightM}
              title={childHeroBanner.title}
              subTitle={childHeroBanner.subTitle}
              paragraph={childHeroBanner.paragraph}
              secondaryColor={childHeroBanner.secondaryColor}
              backgroundColor={childHeroBanner.backgroundColor}
            />
            <ContentBanner
              image={childContent.image}
              paragraph={childContent.paragraph}
              link={childContent.link}
              linkUrl={childContent.linkUrl}
              secondaryColor={childContent.secondaryColor}
              backgroundColor={childContent.backgroundColor}
            />
            <LinkBlock title={childFooter.title} img={childFooter.img} />
          </>
        )
      case 'love':
        const { heroBanner: loveHeroBanner, footer: loveFooter } = loveData
        return (
          <>
            <HeroBannerDetail
              image={loveHeroBanner.image}
              fontFamily={loveHeroBanner.fontFamily}
              fontSizeM={loveHeroBanner.fontSizeM}
              fontheightM={loveHeroBanner.fontheightM}
              title={loveHeroBanner.title}
              subTitle={loveHeroBanner.subTitle}
              paragraph={loveHeroBanner.paragraph}
              secondaryColor={loveHeroBanner.secondaryColor}
              backgroundColor={loveHeroBanner.backgroundColor}
            />
            {API_SITE !== 'FR' && <VideoBlock />}
            <LinkBlock title={loveFooter.title} img={loveFooter.img} />
          </>
        )
      case 'strong':
        const {
          heroBanner: strongHeroBanner,
          content: strongContent,
          carousel: strongCarousel,
          footer: strongFooter,
        } = strongData
        return (
          <>
            <HeroBannerDetail
              image={strongHeroBanner.image}
              fontFamily={strongHeroBanner.fontFamily}
              fontSizeM={strongHeroBanner.fontSizeM}
              fontheightM={strongHeroBanner.fontheightM}
              title={strongHeroBanner.title}
              subTitle={strongHeroBanner.subTitle}
              paragraph={strongHeroBanner.paragraph}
              secondaryColor={strongHeroBanner.secondaryColor}
              backgroundColor={strongHeroBanner.backgroundColor}
            />
            <ContentBanner
              image={strongContent.image}
              paragraph={strongContent.paragraph}
              link={strongContent.link}
              linkUrl={strongContent.linkUrl}
              secondaryColor={strongContent.secondaryColor}
              backgroundColor={strongContent.backgroundColor}
            />
            <Carousel items={strongCarousel} />
            <LinkBlock title={strongFooter.title} img={strongFooter.img} />
          </>
        )
      case 'travel':
        const { heroBanner: travelHeroBanner, footer: travelFooter } = travelData
        return (
          <>
            <HeroBannerDetail
              image={travelHeroBanner.image}
              fontFamily={travelHeroBanner.fontFamily}
              fontSizeM={travelHeroBanner.fontSizeM}
              fontheightM={travelHeroBanner.fontheightM}
              title={travelHeroBanner.title}
              subTitle={travelHeroBanner.subTitle}
              paragraph={travelHeroBanner.paragraph}
              secondaryColor={travelHeroBanner.secondaryColor}
              backgroundColor={travelHeroBanner.backgroundColor}
            />
            <MediaBlock image="/images/landingpage/detailpage/travelmedia.webp" />
            <LinkBlock title={travelFooter.title} img={travelFooter.img} />
          </>
        )
      default:
        const { heroBanner, links, bottomBanner } = linkData
        return (
          <>
            <HeroBanner
              title={heroBanner.title}
              subTitle={heroBanner.subTitle}
              paragraph={heroBanner.paragraph}
              button={heroBanner.button}
            />
            <PageList linkenArry={links} />
            <FooterBanner
              // style={{
              //   // temp removed click event
              //   pointerEvents: 'none',
              //   cursor: 'default',
              // }}
              style={API_SITE !== 'NL' ? { pointerEvents: 'none', cursor: 'default' } : {}}
              title={bottomBanner.title}
              paragraph={bottomBanner.paragraph}
              url={bottomBanner.url}
            />
          </>
        )
    }
  }

  return <LandingPageContainer>{createContentPage()}</LandingPageContainer>
}

export default LandingPage
